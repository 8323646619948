import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from './../components/video';
import TextBox from './../components/text-box';

export default inject("data")(observer(class labyrinth extends Component {

	videoEnded() {
		this.props.goTo("/");

		return false;
	}

	render() {
		return (
			<main id="labyrinth" className="hidden-to-visible-1s">
				<Video videoName="labyrinth.mp4" videoEnded={() => this.videoEnded()}>
					<TextBox min="13" max="20" top="580" left="1400" width="400">{this.props.t("Labyrinth_1")}</TextBox>
					<TextBox min="21" max="24" top="690" left="420" width="300">{this.props.t("Labyrinth_2")}</TextBox>
					<TextBox min="25" max="31" top="580" left="1420" width="400">{this.props.t("Labyrinth_3")}</TextBox>
					<TextBox min="32" max="38" top="520" left="360" width="300">{this.props.t("Labyrinth_4") + "<br/>" + this.props.t("Labyrinth_5")}</TextBox>
					<TextBox min="39" max="48" top="580" left="1400" width="400">{this.props.t("Labyrinth_6")}</TextBox>
					<TextBox min="49" max="52" top="250" left="370" width="220">{this.props.t("Labyrinth_7")}</TextBox>
				</Video>
				<footer>
					<div className="flexed">
						{this.props.t("Labyrinth_title")}
					</div>
				</footer>
			</main>
		);
  	}
}));