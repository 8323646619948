import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { observable } from "mobx";
import * as serviceWorker from './serviceWorker';

let savedData = {
    lastActivityTime : null,
    currentLang : "fr",
    onScreenSaver : false,
};

const data = observable(savedData);

ReactDOM.render(<App data={data} />, document.getElementById('root'));

serviceWorker.unregister();
