import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from '../components/video';
import Button from "../components/button";

export default inject("data")(observer(class Azimut extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showButtons : false,
			btn : 0,

		}
	}

	videoEnded() {
		this.setState({
			btn : 0
		});
		return false;
	}

	timeUpdate(sec) {
		if (this.state.btn === 0) {
			if (!this.state.showButtons && sec > 28) {
				this.setState({
					showButtons : true
				})
			}
		}
	}

	show(num) {
		this.setState({
			btn : num
		})
	}

	render() {
		return (
			<main id="azimut" className="hidden-to-visible-1s">
				<Video videoName="azimut.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }></Video>
				{
					this.state.btn !== 0 ?
						<div>
							<div className="hidden-to-visible-1s sub-video-section">
								{
									this.state.btn === 1 ?
										<Video videoEnded={() => this.videoEnded()} videoName={"azimut_north_" + this.props.data.currentLang + ".mp4"}></Video>
									:
									this.state.btn === 2 ?
										<Video videoEnded={() => this.videoEnded()} videoName={"azimut_south_" + this.props.data.currentLang + ".mp4"}></Video>
									:
									this.state.btn === 3 ?
										<Video videoEnded={() => this.videoEnded()} videoName={"azimut_east_" + this.props.data.currentLang + ".mp4"}></Video>
									:
									this.state.btn === 4 ?
										<Video videoEnded={() => this.videoEnded()} videoName={"azimut_west_" + this.props.data.currentLang + ".mp4"}></Video>
									:
										null
								}
							</div>
							<div className="hidden-to-visible-1s image-frame image-frame-1">
								<img className={this.state.btn === 1 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-top-north.png"} style={{left:"-15px", top: "0"}} alt="1" />
								<img className={this.state.btn === 2 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-top-south.png"} style={{left:"-115px", bottom: "-20px"}} alt="2" />
								<img className={this.state.btn === 3 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-top-east.png"} style={{left:"-30px", top: "-15px"}} alt="3" />
								<img className={this.state.btn === 4 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-top-west.png"} style={{left:"0", top: "-15px"}} alt="4" />
							</div>
							<div className="hidden-to-visible-1s image-frame image-frame-2">
								<img className={this.state.btn === 1 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" }src={"/images/azimut-bottom-north.png"} style={{left:"0", top: "0"}} alt="1" />
								<img className={this.state.btn === 2 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-bottom-south.png"} style={{left:"-47px", top: "-50px"}} alt="2" />
								<img className={this.state.btn === 3 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-bottom-east.png"} style={{left:"-10px", top: "-20px"}} alt="3" />
								<img className={this.state.btn === 4 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" } src={"/images/azimut-bottom-west.png"} style={{left:"-30px", top: "-8px"}}  alt="4" />
							</div>
						</div>
					:
						null
				}
				<footer>
					<div className="flexed">
						<div>
							{this.props.t("Azimut_title")}
						</div>

						{
							this.state.showButtons ?
								<div className="hidden-to-visible-1s">
									<Button onClick={() => this.show(1)}>
										<img
											src={"/images/azimut-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"} alt="1"
											onMouseOut={(evt) => evt.target.src = "/images/azimut-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/azimut-btn-active.png"}
											/>
											<div>{this.props.t("Azimut_N")}</div>
									</Button>
									<Button onClick={() => this.show(2)}>
										<img
											src={"/images/azimut-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"} alt="2"
											onMouseOut={(evt) => evt.target.src = "/images/azimut-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/azimut-btn-active.png"}
											/>
											<div>{this.props.t("Azimut_S")}</div>
									</Button>
									<Button onClick={() => this.show(3)}>
										<img
											src={"/images/azimut-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"} alt="3"
											onMouseOut={(evt) => evt.target.src = "/images/azimut-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/azimut-btn-active.png"}
										/>
										<div>{this.props.t("Azimut_E")}</div>
									</Button>
									<Button onClick={() => this.show(4)}>
										<img
											src={"/images/azimut-btn" + (this.state.btn === 4 ? "-active" : "") + ".png"} alt="4"
											onMouseOut={(evt) => evt.target.src = "/images/azimut-btn" + (this.state.btn === 4 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/azimut-btn-active.png"}
										/>
										<div>{this.props.t("Azimut_W")}</div>
									</Button>
								</div>
							:
								<div></div>
							}
					</div>
				</footer>
			</main>
		);
  	}
}));