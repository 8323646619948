import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from '../components/video';
import TextBox from '../components/text-box';

export default inject("data")(observer(class Tower extends Component {

	videoEnded() {
		this.props.goTo("/");

		return false;
	}

	render() {
		return (
			<main id="tower" className="hidden-to-visible-1s">
				<Video videoName="tower.mp4" videoEnded={() => this.videoEnded()}>
					<TextBox min="18" max="24" top="150" left="250" width="400">{this.props.t("Tower_1")}</TextBox>
					<TextBox min="25" max="28" top="150" left="250" width="400">{this.props.t("Tower_2")}</TextBox>
					<TextBox min="29" max="33" top="220" left="470" width="300">{this.props.t("Tower_3")}</TextBox>
					<TextBox min="34" max="41" top="150" left="250" width="400">{this.props.t("Tower_4")}</TextBox>
					<TextBox min="42" max="46" top="580" left="1400" width="400">{this.props.t("Tower_5")}</TextBox>
					<TextBox min="47" max="57" top="150" left="250" width="400">{this.props.t("Tower_6")}</TextBox>
					<TextBox min="58" max="62" top="580" left="1300" width="400">{this.props.t("Tower_7")}</TextBox>
					<TextBox min="62" max="70" top="150" left="250" width="400">{this.props.t("Tower_8")}</TextBox>
					<TextBox min="81" max="90" top="150" left="250" width="400">{this.props.t("Tower_9")}</TextBox>
				</Video>
				<footer>
					<div className="flexed">
						{this.props.t("Tower_title")}
					</div>
				</footer>
			</main>
		);
  	}
}));