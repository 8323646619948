import en from './en';
import fr from './fr';

export default class Translator {
    constructor() {
        this.locales = [];
        
		this.locales["en"] = en;
		this.locales["fr"] = fr;
    }

    read(lang, key) {
        return this.locales[lang][key];
    }
}