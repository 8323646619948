import React, { Component} from 'react';
import { observer, inject } from "mobx-react";

export default inject("data")(observer(class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoSec : 0
        }
    }

    videoEnded(target) {
        let mustsLoop = true;

        if (this.props.videoEnded != null) {
            mustsLoop = this.props.videoEnded();
        }

        if (mustsLoop) {
            target.play();
        }
    }

	timeUpdate(evt) {
        let sec = Math.ceil(evt.target.currentTime);

        if (this.props.timeUpdate != null) {
            this.props.timeUpdate(sec);
        }

        this.setState({
            videoSec : sec
        });        
	}
    
    render() {
        let boxId = 0;

        return <div>
                    <video 
                        src={"videos/"  + this.props.videoName}
                        muted autoPlay 
                        onEnded={(evt) => this.videoEnded(evt.target)}
                        onTimeUpdate={evt => this.timeUpdate(evt)}                
                    ></video>

                    {
                        React.Children.map(this.props.children, textBox => {
                            if (textBox != null) {
                                return (
                                    <div key={this.props.videoName + "-" + boxId++} 
                                        className={(textBox.props.min > this.state.videoSec ? "hidden" : textBox.props.max == null || textBox.props.max > this.state.videoSec ? "hidden-to-visible-1s" : "visible-to-hidden-1s")}>
                                        {textBox}
                                    </div>
                                )
                            }
                            else{
                                return null;
                            }
                        })
                    }
                </div>;
    }
}));