import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from '../components/video';
import Button from "../components/button";
import TextBox from '../components/text-box';

export default inject("data")(observer(class Building extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showButtons : false,
			btn : "0",
			previousBtn : "0",
		}
	}

	videoEnded() {
		return false;
	}

	timeUpdate(sec) {
		if (!this.state.showButtons && sec > 10) {
			this.setState({
				showButtons : true
			})
		}
	}

	show(num) {
		this.setState({
			btn : num,
			previousBtn : this.state.btn
		})
	}

	render() {
		return (
			<main id="building" className="hidden-to-visible-1s">
				{
					this.state.showButtons ?
						<div>
							<div className={"overlap-img " + (this.state.btn === "1" ? "hidden-to-visible-1s" : this.state.previousBtn === "1" ? "visible-to-hidden-1s" : "hidden")}>
								<img src="/images/building-1.png" alt="building-1" />
							</div>
							<div className={"overlap-img " + (this.state.btn === "2" ? "hidden-to-visible-1s" : this.state.previousBtn === "2" ? "visible-to-hidden-1s" : "hidden" )}>
								<img src="/images/building-2.png" alt="building-2" />
							</div>
							<div className={"overlap-img " + (this.state.btn === "3" ? "hidden-to-visible-1s" : this.state.previousBtn === "3" ? "visible-to-hidden-1s" : "hidden" )}>
								<img src="/images/building-3.png" alt="building-3" />
							</div>
						</div>
					:
						null
				}

				<Video videoName="building.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }>
					<TextBox min="3" max="9" top="200" left="760" width="400">{this.props.t("Building_1")}</TextBox>

					<TextBox min={this.state.btn === "1" ? 0 : 100} top="200" left="500" width="400">
						{"<div class='text-box-title'>" + this.props.t("Building_btn_1") + "</div>" + this.props.t("Building_2")}
					</TextBox>
					<TextBox min={this.state.btn === "2" ? 0 : 100} top="150" left="830" width="450">
						{"<div class='text-box-title'>" + this.props.t("Building_btn_2") + "</div>" + this.props.t("Building_3")}
					</TextBox>
					<TextBox min={this.state.btn === "3" ? 0 : 100} top="200" left="1300" width="500">
						{"<div class='text-box-title'>" + this.props.t("Building_btn_3") + "</div>" + this.props.t("Building_4")}
					</TextBox>
				</Video>

				<footer>
					<div className="flexed">
						<div>
							{this.props.t("Building_title")}
						</div>
							{
								this.state.showButtons ?
									<div className="hidden-to-visible-1s">
										<Button onClick={() => this.show("1")}>
											<img
												src={"/images/building-btn" + (this.state.btn === "1" ? "-active" : "") + ".png"} alt="1"
												onMouseOut={(evt) => evt.target.src = "/images/building-btn" + (this.state.btn === "1" ? "-active" : "") + ".png"}
												onMouseDown={(evt) => evt.target.src = "/images/building-btn-active.png"}
											 />
											 <div>{this.props.t("Building_btn_1")}</div>
										</Button>
										<Button onClick={() => this.show("2")}>
											<img
												src={"/images/building-btn" + (this.state.btn === "2" ? "-active" : "") + ".png"} alt="2"
												onMouseOut={(evt) => evt.target.src = "/images/building-btn" + (this.state.btn === "2" ? "-active" : "") + ".png"}
												onMouseDown={(evt) => evt.target.src = "/images/building-btn-active.png"}
											 />
											 <div>{this.props.t("Building_btn_2")}</div>
										</Button>
										<Button onClick={() => this.show("3")}>
											<img
												src={"/images/building-btn" + (this.state.btn === "3" ? "-active" : "") + ".png"} alt="3"
												onMouseOut={(evt) => evt.target.src = "/images/building-btn" + (this.state.btn === "3" ? "-active" : "") + ".png"}
												onMouseDown={(evt) => evt.target.src = "/images/building-btn-active.png"}
											/>
											<div>{this.props.t("Building_btn_3")}</div>
										</Button>
									</div>
								:
									<div></div>
							}
					</div>
				</footer>
			</main>
		);
  	}
}));