import React, { Component } from 'react';

import Home from "./pages/home.js";
import Labyrinth from './pages/labyrinth.js';
import Building from './pages/building.js';
import Tower from './pages/tower.js';
import Azimut from './pages/azimut.js';
import Here from './pages/here.js';
import Architecture from './pages/architecture.js';
import { observer, Provider } from "mobx-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PageHOC from './components/page-hoc';

import './scss/global.scss';

export default observer(class App extends Component {
	constructor(props) {
		super(props);

		this.stores = {
			data: this.props.data
		};
	}

	render() {

		return (
			<Provider {...this.stores}>
				<Router>
					<div className="App">
						<main role="main" className="container">
							<PropsRoute exact path="/" component={PageHOC(Home)} pageName="Accueil" />
							<PropsRoute exact path="/labyrinth" component={PageHOC(Labyrinth)} pageName="Labyrinthe" />
							<PropsRoute exact path="/building" component={PageHOC(Building)} pageName="Bâtiment" />
							<PropsRoute exact path="/tower" component={PageHOC(Tower)} pageName="Tour" />
							<PropsRoute exact path="/architecture" component={PageHOC(Architecture)} pageName="Architecture" />
							<PropsRoute exact path="/azimut" component={PageHOC(Azimut)} pageName="Azimut" />
							<PropsRoute exact path="/here" component={PageHOC(Here)} pageName="Ici" />
						</main>
					</div>
				</Router>
			</Provider>
		);
	}
})
const renderMergedProps = (component, ...rest) => {
	const finalProps = Object.assign({}, ...rest);
	return (
	  React.createElement(component, finalProps)
	);
}

const PropsRoute = ({ component, ...rest }) => {
	return (
		<Route {...rest} render={routeProps => {
		return renderMergedProps(component, routeProps, rest);
	}}/>);
}