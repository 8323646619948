import React, { Component} from 'react';
import { observer, inject } from "mobx-react";

export default inject("data")(observer(class TextBox extends Component {
    
    render() {
        return <div 
                className="text-box"
                style={{
                    width: this.props.width + "px",
                    top: this.props.top + "px",
                    left: this.props.left + "px",
                }}> 
                    <div dangerouslySetInnerHTML={{ __html: this.props.children}}></div>
                </div>;
    }
}));