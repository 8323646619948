import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from '../components/video';
import TextBox from '../components/text-box';
import Button from "../components/button";

export default inject("data")(observer(class Architecture extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showButtons : false,
			btn : 0
		}
	}

	videoEnded() {
		if (this.state.btn === 3) {
			this.setState({
				btn : 0
			});
		}

		return false;
	}

	timeUpdate(sec) {
		if (this.state.btn === 0) {
			if (!this.state.showButtons && sec > 12) {
				this.setState({
					showButtons : true
				})
			}
		}
	}

	show(num) {
		this.setState({
			btn : num
		})
	}

	render() {
		return (
			<main id="architecture">
				<Video videoName="architecture.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }>
					<TextBox min="5" max="13" top="200" left="710" width="500">{this.props.t("Architecture_1")}</TextBox>
				</Video>

				{
					this.state.btn === 1 ?
						<div key="video-1" className="visible">
							<Video videoName="architecture_sym.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }>
								<TextBox min="15" top="200" left="1110" width="400">{this.props.t("Architecture_2")}</TextBox>
							</Video>
						</div>
					:
					this.state.btn === 2 ?
						<div key="video-2" className="visible">
							<Video videoName="architecture_tri.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }>
								<TextBox min="12" top="200" left="170" width="400">{this.props.t("Architecture_3")}</TextBox>
								<TextBox min="14" top="730" left="1400" width="350">{this.props.t("Architecture_4")}</TextBox>
								<TextBox min="14" top="440" left="1400" width="350">{this.props.t("Architecture_5")}</TextBox>
								<TextBox min="14" top="120" left="1400" width="350">{this.props.t("Architecture_6")}</TextBox>
							</Video>
						</div>
					:
					this.state.btn === 3 ?
						<div key="video-3" className="visible">
							<Video videoName="architecture_tower.mp4" videoEnded={() => this.videoEnded()} timeUpdate={sec => this.timeUpdate(sec) }>
								<TextBox min="13" max="37" top="600" left="350" width="400">{this.props.t("Architecture_7")}</TextBox>
							</Video>
						</div>
					:
						<div></div>
				}

				<footer className={"hidden-to-visible-1s" }>
					<div className="flexed">
						<div dangerouslySetInnerHTML={{ __html: this.props.t("Architecture_title")}}></div>
						{
							this.state.showButtons ?
								<div className="hidden-to-visible-1s">
									<Button onClick={() => this.show(1)}>
										<img
											src={"/images/architecture-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"} alt="1"
											onMouseOut={(evt) => evt.target.src = "/images/architecture-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/architecture-btn-active.png"}
											/>
											<div>{this.props.t("Architecture_btn_1")}</div>
									</Button>
									<Button onClick={() => this.show(2)}>
										<img
											src={"/images/architecture-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"} alt="2"
											onMouseOut={(evt) => evt.target.src = "/images/architecture-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/architecture-btn-active.png"}
											/>
											<div>{this.props.t("Architecture_btn_2")}</div>
									</Button>
									<Button onClick={() => this.show(3)}>
										<img
											src={"/images/architecture-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"} alt="3"
											onMouseOut={(evt) => evt.target.src = "/images/architecture-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/architecture-btn-active.png"}
										/>
										<div>{this.props.t("Architecture_btn_3")}</div>
									</Button>
								</div>
							:
								<div></div>
						}
					</div>
				</footer>
			</main>
		);
  	}
}));