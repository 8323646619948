import React from 'react';
import { observer, inject } from "mobx-react";
import { SCREENSAVER_DELAY, HIDE_CURSOR } from '../constants';
import Button from "../components/button";
import Translator from '../locales/translator';

export default function requireAuth(Component) {
	class PageHOC extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				actionAllowed : true
			}

			this.screensaverTimeoutId = null;
			this.screensaverDelay = SCREENSAVER_DELAY;
			this.translator = new Translator();
		}

		componentWillMount() {
			document.title = this.props.displayName != null ? this.props.displayName : this.props.pageName;
		}

		componentDidMount() {
			this.enableScreensaver();
		}

		componentWillUnmount() {
			this.disableScreensaver();
		}

		checkActivity() {
			this.screenSaverTimeoutId = setTimeout(() => {
				this.screenSaverTimeoutId = null;

				if (new Date().getTime() - this.props.data.lastActivityTime > this.screensaverDelay) {
					this.props.history.push('/');
					this.props.data.onScreenSaver = true;
					this.props.data.forcedHiddenMenu = false;
				}
				else {
					this.checkActivity();
				}
			}, 15 * 1000);
		}

		enableScreensaver() {
			if (this.screenSaverTimeoutId == null) {
				this.props.data.lastActivityTime = new Date().getTime();
				this.checkActivity();
			}
		}

		disableScreensaver() {
			if (this.screenSaverTimeoutId != null) {
				clearTimeout(this.screenSaverTimeoutId);
				this.screenSaverTimeoutId = null;
			}
		}

		screenClicked() {
			if (this.props.data.onScreenSaver) {
				this.props.data.lastActivityTime = new Date().getTime();
				this.props.data.onScreenSaver = false;
				this.enableScreensaver();
			}
		}

		switchLang() {
			this.props.data.currentLang = this.props.data.currentLang === "fr" ? "en" : "fr";
		}

		goTo(path) {
			if (this.state.actionAllowed && path !== this.props.location.pathname) {
				this.setState({
					actionAllowed : false
				});

				setTimeout(() => {
					this.props.history.push(path);
				}, 1000);
			}
		}

		read(key, forceLang = null) {
			return this.translator.read(forceLang != null ? forceLang : this.props.data.currentLang, key);
		}

		render() {

			return <div onClick={ () => this.screenClicked() } style={{ cursor : HIDE_CURSOR ? "none" : "auto"}} className={this.state.actionAllowed ? "" : "visible-to-hidden-1s"}>
						<Component {...this.props} t={(key, forceLang = null) => this.read(key, forceLang)} goTo={p => this.goTo(p)} enableScreensaver={() => this.enableScreensaver()} disableScreensaver={() => this.disableScreensaver()} />
						{
							!this.props.data.onScreenSaver && !this.props.data.forcedHiddenMenu ?
								<nav className="hidden-to-visible-1s primary-nav">
									<Button onClick={() => {this.goTo("/labyrinth")}}>
										<img src={"/images/lab" + (this.props.pageName === "Labyrinthe" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/lab" + (this.props.pageName === "Labyrinthe" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/lab-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.goTo("/building")}>
										<img src={"/images/building" + (this.props.pageName === "Bâtiment" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/building" + (this.props.pageName === "Bâtiment" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/building-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.goTo("/tower")}>
										<img src={"/images/tower" + (this.props.pageName === "Tour" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/tower" + (this.props.pageName === "Tour" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/tower-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.goTo("/architecture")}>
										<img src={"/images/architecture" + (this.props.pageName === "Architecture" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/architecture" + (this.props.pageName === "Architecture" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/architecture-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.goTo("/azimut")}>
										<img src={"/images/azimut" + (this.props.pageName === "Azimut" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/azimut" + (this.props.pageName === "Azimut" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/azimut-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.goTo("/here")}>
										<img src={"/images/here" + (this.props.pageName === "Ici" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/here" + (this.props.pageName === "Ici" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/here-active.png"} alt="png" />
									</Button>
								</nav>
							:
								null
						}
						{
							!this.props.data.onScreenSaver && !this.props.data.forcedHiddenMenu ?
								<nav className="hidden-to-visible secondary-nav">
									<Button onClick={() => this.goTo("/")}>
										<img src={"/images/home" + (this.props.pageName === "Accueil" ? "-active" : "") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/home" + (this.props.pageName === "Accueil" ? "-active" : "") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/home-active.png"} alt="png" />
									</Button>
									<Button onClick={() => this.switchLang()}>
										<img src={"/images/lang-" + (this.props.data.currentLang === "fr" ? "fr" : "en") + ".png"}
											onMouseOut={(evt) => evt.target.src = "/images/lang-" + (this.props.data.currentLang === "fr" ? "fr" : "en") + ".png"}
											onMouseDown={(evt) => evt.target.src = "/images/lang-" + (this.props.data.currentLang === "fr" ? "en" : "fr") + ".png"} alt="png" />
									</Button>
								</nav>
							:
								null
						}
				   </div>
		}
	}

	return inject("data")(observer(PageHOC));
}