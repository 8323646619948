import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import Video from './../components/video';
import TextBox from './../components/text-box';

export default inject("data")(observer(class Home extends Component {

	componentWillMount() {
		this.props.data.forcedHiddenMenu = true;
	}

	videoEnded() {
		this.props.data.forcedHiddenMenu = false;

		return true;
	}

	showMenu() {
		if (this.props.data.forcedHiddenMenu) {
			this.props.data.forcedHiddenMenu = false;
		}
	}

	timeUpdate(sec) {

		if (sec >= 5 && this.props.data.forcedHiddenMenu) {
			this.props.data.forcedHiddenMenu = false;
		}
	}

	render() {
		return (
			<main id="home" className="hidden-to-visible-1s" onClick={() => this.showMenu()}>
				<Video videoName="balade.mp4" timeUpdate={sec => this.timeUpdate(sec) }>
					<TextBox min="5" max="10" top="850" left="760" width="400">{this.props.t("Home_1", "fr")}</TextBox>
					<TextBox min="15" max="20" top="850" left="760" width="400">{this.props.t("Home_1", "en")}</TextBox>
					<TextBox min="25" max="30" top="850" left="760" width="400">{this.props.t("Home_1", "fr")}</TextBox>
					<TextBox min="35" max="40" top="850" left="760" width="400">{this.props.t("Home_1", "en")}</TextBox>
					<TextBox min="45" max="50" top="850" left="760" width="400">{this.props.t("Home_1", "fr")}</TextBox>
				</Video>
			</main>
		);
  	}
}));