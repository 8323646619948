import React, { Component} from 'react';
import { observer, inject  } from "mobx-react";
import TextBox from '../components/text-box';
import Button from "../components/button";

export default inject("data")(observer(class Here extends Component {
	constructor(props) {
		super(props);

		this.state = {
			btn : 0,
			previousBtn : 0,
		}
	}

	show(num) {
		this.setState({
			btn : num,
			previousBtn : this.state.btn,
		})
	}

	render() {
		return (
			<main id="here" className="hidden-to-visible-1s">
				<div>
					<div className={"overlap-img-here " + (this.state.btn === 0 ? "hidden-to-visible-1s" : this.state.previousBtn === 0 ? "visible" : "hidden" )}>
						<img src="/images/here-none.png" alt="here-0" />
					</div>
					<div className={"overlap-img-here " + (this.state.btn === 1 ? "visible" : this.state.previousBtn === 1 ? "visible" : "hidden")}>
						<img src="/images/here-labyrinth.png" alt="here-1" />
					</div>
					<div className={"overlap-img-here " + (this.state.btn === 2 ? "visible" : this.state.previousBtn === 2 ? "visible" : "hidden" )}>
						<img src="/images/here-building.png" alt="here-2" />
					</div>
					<div className={"overlap-img-here " + (this.state.btn === 3 ? "visible" : this.state.previousBtn === 3 ? "visible" : "hidden" )}>
						<img src="/images/here-tower.png" alt="here-3" />
					</div>
				</div>

				<div className={"here-box " + (this.state.btn === 0 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" )}>
					<TextBox min="0" top="350" left="1220" width="500">{this.props.t("Here_1")}</TextBox>
				</div>

				<div className={this.state.btn === 0 ? "hidden" : "" }>
					<div className={"labyrinth-box " + (this.state.btn === 1 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" )}>
						<TextBox min="0" top="400" left="1200" width="500">{this.props.t("Here_2")}</TextBox>
					</div>

					<div className={"building-box " + (this.state.btn === 2 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" )}>
						<TextBox min="0" top="450" left="1200" width="500">{this.props.t("Here_3")}</TextBox>
					</div>

					<div className={"tower-box " + (this.state.btn === 3 ? "hidden-to-visible-1s" : "visible-to-hidden-1s" )}>
						<TextBox min="0" top="500" left="1200" width="500">{this.props.t("Here_4")}</TextBox>
					</div>
				</div>

				<footer>
					<div className="flexed">
						<div>
							{this.props.t("Here_title")}
						</div>
						<div>
							<Button onClick={() => this.show(1)}>
								<img
									src={"/images/here-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"} alt="1"
									onMouseOut={(evt) => evt.target.src = "/images/here-btn" + (this.state.btn === 1 ? "-active" : "") + ".png"}
									onMouseDown={(evt) => evt.target.src = "/images/here-btn-active.png"}
									/>
									<div>{this.props.t("Here_btn_1")}</div>
							</Button>
							<Button onClick={() => this.show(2)}>
								<img
									src={"/images/here-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"} alt="2"
									onMouseOut={(evt) => evt.target.src = "/images/here-btn" + (this.state.btn === 2 ? "-active" : "") + ".png"}
									onMouseDown={(evt) => evt.target.src = "/images/here-btn-active.png"}
									/>
									<div>{this.props.t("Here_btn_2")}</div>
							</Button>
							<Button onClick={() => this.show(3)}>
								<img
									src={"/images/here-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"} alt="3"
									onMouseOut={(evt) => evt.target.src = "/images/here-btn" + (this.state.btn === 3 ? "-active" : "") + ".png"}
									onMouseDown={(evt) => evt.target.src = "/images/here-btn-active.png"}
								/>
								<div>{this.props.t("Here_btn_3")}</div>
							</Button>
						</div>
					</div>
				</footer>
			</main>
		);
  	}
}));